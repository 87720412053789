import { OfferData } from './../types/offer-data.interface';
import { WebsocketSignalRService } from './websocket-signalr.service';
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OfferModalComponent } from '../components/misc-alerts/offer-modal/offer-modal.component';
import { from, Observable, of } from 'rxjs';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { OfferType } from '../enums/offer-type.enum';
import { PremiumModalComponent } from '../components/premium/modal/premium-modal.component';
import { ModalAnimationsService } from '../animations/modal-animations.service';

@Injectable({
  providedIn: 'root',
})
export class OffersModalService {
  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _modalCtrl: ModalController,
    private _modalAnimationsService: ModalAnimationsService
  ) {}

  checkPopupOffer(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<OfferData>(WebsocketCommandType.getOffer, {})
      .pipe(
        switchMap((res) => {
          if (res) {
            const knownOfferTypes = this.getKnownOfferTypes();
            if (!knownOfferTypes.includes(res.type)) {
              return of(false);
            }

            if (
              res.type === OfferType.subscription ||
              res.type === OfferType.subscriptionContinue ||
              res.type === OfferType.subscriptionRenewal
            ) {
              return this.showPremiumModal(res, 'checkPopupOffer');
            } else {
              this.showOffer(res);
              return of(true);
            }
          }
          return of(false);
        })
      );
  }

  checkOfferBanners(): Observable<OfferData[]> {
    return this._websocketSignalRService
      .invoke<OfferData[]>(WebsocketCommandType.getOffers, {})
      .pipe(
        map((res) => {
          if (res) {
            const knownOfferTypes = this.getKnownOfferTypes();
            return res.filter((el) => knownOfferTypes.includes(el.type));
          }
        })
      );
  }

  public showSubscriptionOffer(callFrom: string): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<OfferData>(WebsocketCommandType.getSubscriptionOffer, {})
      .pipe(
        switchMap((res) => {
          if (res) {
            return this.showPremiumModal(res, callFrom);
          } else {
            return of(false);
          }
        })
      );
  }

  showOffer(offerData: OfferData) {
    this._modalCtrl
      .create({
        component: OfferModalComponent,
        componentProps: {
          offerData,
        },
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
        enterAnimation: this._modalAnimationsService.enterAnimation,
        leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
      .then((modal) => modal.present());
  }

  showPremiumModal(
    offerData: OfferData,
    callFrom: string
  ): Observable<boolean> {
    return from(
      this._modalCtrl.create({
        component: PremiumModalComponent,
        componentProps: {
          offerData,
          callFrom,
        },
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
        enterAnimation: this._modalAnimationsService.enterAnimation,
        leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
    ).pipe(
      switchMap((modal) => {
        modal.present();
        return modal.onDidDismiss();
      }),
      map((res) => !!res)
    );
  }

  private getKnownOfferTypes(): string[] {
    const knownOfferTypes: string[] = [];
    for (const key in OfferType) {
      if (Object.prototype.hasOwnProperty.call(OfferType, key)) {
        knownOfferTypes.push(OfferType[key]);
      }
    }
    return knownOfferTypes;
  }
}
