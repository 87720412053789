import { environment } from 'src/environments/environment';
import { UiStatesEnum } from './../../enums/ui-states.enum';
import { UnsubscribeHelper } from './../../helpers/unsubscribe.helper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UiStates } from '../../enums/uiStates';
import { NavHelper } from '../../helpers';
import { UiStateService } from '../../services/comunication_services/uiStates.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { MessageParser } from '../../helpers/messageParser';
import { filter, take } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { BonuseDataService } from '../../services/comunication_services/bonusData.service';
import { Subscription } from 'rxjs';
import { BonusService } from '../../services/API_services/bonus.service';

export interface MenuButtonsOptions {
  name: string;
  btn: AnimationItem;
  clicked: boolean;
  icon: string;
  options: AnimationOptions;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public environment = environment;
  public state: UiStates;
  public selectedMenuButton: UiStates;
  public visibleMenuButtons: UiStates[] = [];
  public uiStates = UiStates;
  public hasBonusRewards = false;
  private _menuButtons: UiStates[] = [
    UiStates.gallery,
    UiStates.bonus,
    UiStates.chat,
    UiStates.swipe,
    UiStates.shop,
    UiStates.settings,
  ];
  private subscribers: Subscription[] = [];
  isShowBottomSpacer =
    this._platform.is('ios') && !this._platform.is('mobileweb');

  constructor(
    public messageParser: MessageParser,
    private _navHelper: NavHelper,
    private _uiStateService: UiStateService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _platform: Platform,
    private _bonuseDataService: BonuseDataService,
    private _bonusService: BonusService
  ) {}

  ngOnInit() {
    this._uiStateService.showSwipingCards
      .pipe(
        filter((res) => res !== null),
        take(1)
      )
      .subscribe((showCards) => {
        if (!showCards) {
          this.visibleMenuButtons = this._menuButtons.filter(
            (e) => e !== 'swipe'
          );
        } else {
          this.visibleMenuButtons = this._menuButtons;
        }
      });

    this.subscribers
      .push
      // this._nextBonusTimeService.updateNextBonusTime.subscribe(async (res) => {
      //   this.time = res.str;
      //   const bonusBtn = this.menuButtonsOptions.filter(
      //     (r) => r.name === 'bonus'
      //   )[0]?.btn;
      //   bonusBtn?.renderer?.elements[5]?.updateDocumentData({ t: this.time });
      //   bonusBtn?.playSegments([0, 1], true);
      // })
      ();

    this.state = UiStates.main;

    this.subscribers.push(
      this._uiStateService.updateStateData.subscribe((res) => {
        this.state = res[UiStatesEnum.state];
        this.setCurrentFooterState(this.state);
      })
    );

    // this.subscribers.push(
    //   this._bonuseDataService.updateBonusData.subscribe((res) => {
    //     this.hasBonusRewards = res.find(
    //       (reward) =>
    //         reward.isCurrentDay &&
    //         !reward.timeToNextReward &&
    //         !reward.isClaimped
    //     )
    //       ? true
    //       : false;
    //   })
    // );
  }

  private setCurrentFooterState(state: UiStates): void {
    switch (state) {
      case UiStates.album:
        this.selectedMenuButton = UiStates.gallery;
        break;
      case UiStates.main:
        this.selectedMenuButton = UiStates.chat;
        break;
      case UiStates.questLines:
        this.selectedMenuButton = UiStates.bonus;
        break;
      default:
        this.selectedMenuButton = state;
        break;
    }
  }

  public goTo(state: UiStates): void {
    switch (state) {
      case UiStates.gallery:
        return this._navHelper.goToGallery();
      case UiStates.chat:
        return this._navHelper.goToAllChats();
      case UiStates.settings:
        return this._navHelper.goToSettings();
      case UiStates.bonus:
        return this._navHelper.goToQuestLines();
      case UiStates.shop:
        return this._navHelper.goToShopPacks();
      case UiStates.swipe:
        return this._navHelper.goToCards();
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this.subscribers);
  }
}
