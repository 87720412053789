import {
  SplitTestCase,
  SplitTestCasesService,
} from 'src/app/shared/services/split-test-cases.service';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap, catchError, take, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';
import { SignMethod } from '../../enums/sign-method.enum';
import { NavHelper } from '../../helpers';
import { Clean } from '../../helpers/clean';
import { AuthResponse } from '../../types/auth-response.interface';
import { AuthPayloadData } from '../../types/auth-payload-data.interface';
import { TwitterSignInRequest } from '../../types/twitter-signIn-request.interface';
import { AnalyticsService } from '../analytics/analytics.service';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { TutorialService } from '../comunication_services/tutorial.service';
import { AuthApiService } from './auth-api.service';
import { AuthStateService } from './auth-state.service';
import { IncomePayloadDataService } from '../income-payload-data.service';
import { SettingsDataService } from '../comunication_services/settingsData.sevice';
import {
  ServerPlatform,
  UserMyResponse,
} from '../../types/user-my-response.interface';
import { UiStateService } from '../comunication_services/uiStates.service';
import { CheckUpdatesService } from '../check-updates.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { NotificationService } from '../notifications_services/notification.service';
import { LegalPaymentService } from '../payments/legal-payment.service';
import { TranslateConfigService } from '../translate-config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthMethodsService {
  private isHaveData = false;
  constructor(
    private _authApiService: AuthApiService,
    private _authStateService: AuthStateService,
    private _tokenService: JWTTokenService,
    private _analyticsService: AnalyticsService,
    private _clean: Clean,
    private _tutorialService: TutorialService,
    private _navHelper: NavHelper,
    private _incomePayloadDataService: IncomePayloadDataService,
    private _settingsDataService: SettingsDataService,
    private _splitTestCasesService: SplitTestCasesService,
    private _uiStateService: UiStateService,
    private _checkUpdatesService: CheckUpdatesService,
    private _notificationService: NotificationService,
    // private _legalPaymentService: LegalPaymentService,
    private _translateConfigService: TranslateConfigService
  ) {
    this._settingsDataService.updateSettingsData.subscribe((res) => {
      if (res.id) {
        this.isHaveData = true;
      } else {
        this.isHaveData = false;
      }
    });
  }

  public getUser(getAnyway: boolean = false): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (!this.isHaveData) {
        this._authApiService
          .getUser()
          .pipe(
            take(1),
            tap((res) => {
              this._translateConfigService.init(res.lang);

              console.log('res.serverPlatform', res.serverPlatform);
              console.log('environment.buildVersion', environment.buildVersion);
              if (
                res.serverPlatform === ServerPlatform.gmn &&
                environment.buildVersion !== BuildVersion.default
              ) {
                // The problems with Nutaku and gmn and stores cookies conflict.
                alert(
                  'Error Code 7. Try to clean your storage and restart the app. Logging out. '
                );
                this._clean.cleanAllData();
                this.logout();
              }

              this._notificationService.addNotifications(res.notifications);
            })
          )
          .subscribe({
            next: (response) => {
              this.saveUserData(response);

              this._splitTestCasesService.init(response.splitTests);

              // this._setNicknameService.init(response.isNickNameSet); // Moved to chats

              this._checkUpdatesService.checkUpdates(response);
              this._authStateService.isGuest = response.isGuest;
              this._analyticsService.setIsGuest(response.isGuest);
              resolve(true);
            },
            error: (err) => {
              resolve(false);
            },
          });
      } else {
        resolve(true);
      }
    });
  }

  private saveUserData(data: UserMyResponse) {
    const noTinderCase =
      data?.splitTests?.find((test) => test === SplitTestCase.onlyChats) ||
      false;
    this._uiStateService.setShowSwipingCards(!noTinderCase);
    this._settingsDataService.changeAllObject({
      avatar: data.avatar,
      email: data.email,
      id: data.id,
      music: data.music,
      nickName: data.nickName,
      isNickNameSet: data.isNickNameSet,
      isAgeConfirmed: data.isAgeConfirmed,
      isAgreementAccepted: data.isAgreementAccepted,
      sound: data.sound,
      quickMessaging: data.quickMessaging,
      onlineStreamNotifications: data.onlineStreamNotifications,
      subscriptionStatus: data.subscriptionStatus,
      ve: data.ve,
      vd: data.vd,
      vn: data.vn,
      vg: data.vg,
      va: data.va,
      config: {...data.config}
    });
    this._settingsDataService.clientId = data.id;
    this._analyticsService.setUserId(data.id);
    this._uiStateService.changeData(UiStatesEnum.diamonds, data.coins);
  }

  private _afterSignUp(
    response: AuthResponse & { method: SignMethod }
  ): Observable<boolean> {
    console.log('_afterSignUp', response);
    // alert('aftersign: ' + JSON.stringify(response));

    if (!response.token || response.token === null) {
      return of(false);
    }
    this._tokenService.removeToken();
    this._clean.cleanSofData();

    // if(environment.buildVersion === BuildVersion.legal) {

    //   this._legalPaymentService.setValidator(response.token);
    // }
    this._tokenService.setToken(response.token);
    this._authStateService.authStatus = AuthStatus.authenticated;
    this._authStateService.isGuest = response.isGuest;
    this._analyticsService.authorize(
      response.method,
      response.isNewUser ? 'sign_up' : 'log_in'
    );

    return from(this.getUser()).pipe(
      switchMap(() => {
        if (!response.tutorialIsPassed) {
          this._tutorialService.setTutorialDataAndRun({
            tutorialChatId: response.tutorialChatId,
            tutorialIsPassed: response.tutorialIsPassed,
            tutorialStep: response.tutorialStep,
          });
        } else {
          if (environment.buildVersion === BuildVersion.erogames) {
            this._navHelper.goToMain();
          }

          if (environment.buildVersion === BuildVersion.nutaku) {
            this._navHelper.goToMain();
          }

          if (response.firstChatId) {
            this._navHelper.goToChat(response.firstChatId);
          }
        }
        this._authStateService.hideRegistrationForm(true);
        return of(true);
      })
    );
  }

  signIn(email: string, password: string): Observable<boolean> {
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      payload,
    };
    return this._authApiService.signIn(data).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.email,
        })
      )
    );
  }
  nutakuSignIn(email: string, password: string): Observable<boolean> {
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      payload,
    };
    return this._authApiService.signWithNutakuApk(data).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.email,
        })
      )
    );
  }

  signUp(
    email: string,
    password: string,
    nickName: string
  ): Observable<boolean> {
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      nickName,
      payload,
    };
    return this._authApiService.signUp(data).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.email,
        })
      )
    );
  }

  signUpWithGoogle(tokenId: string): Observable<boolean> {
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    const data = {
      token: tokenId,
      payload,
    };
    return this._authApiService.signUpWithGoogle(data).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.google,
        })
      )
    );
  }

  signUpWithApple(appleData: any): Observable<boolean> {
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    const data = {
      ...appleData,
      payload,
    };
    return this._authApiService.signUpWithApple(data).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.google,
        })
      )
    );
  }

  signUpWithDiscord(code: string): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload = this._getAndRemovePayloadData();
    const data = {
      code,
      redirectUrl: window.location.origin,
      payload,
    };
    return this._authApiService.signUpWithDiscord(data).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.discord,
        })
      ),
      catchError((error) => {
        this.logout();
        throw error;
      })
    );
  }

  signWithTwitter(data: TwitterSignInRequest): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    return this._authApiService
      .signWithTwitter({
        ...data,
        payload,
      })
      .pipe(
        switchMap((response: AuthResponse) =>
          this._afterSignUp({
            ...response,
            method: SignMethod.twitter,
          })
        ),
        catchError((error) => {
          this.logout();
          throw error;
        })
      );
  }

  trySignAsGuest(): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    return this._authApiService.trySignupAsGuest(payload).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.asGuest,
        })
      )
    );
  }

  signBySwitchTokenApk(): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    console.log('sugn pl', payload);
    return this._authApiService.signBySwitchTokenApk(payload).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.asGuest,
        })
      )
    );
  }

  signByPayment(paymentId: string): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    return this._authApiService.signByPayment(payload, paymentId).pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.authByPayment,
        })
      )
    );
  }

  signWithErogames(): Observable<boolean> {
    const erogamesToken = this._tokenService.erogamesToken;
    const whiteLabel =
      (document.referrer && new URL(document.referrer)?.hostname) || null;
    return this._authApiService
      .signWithErogames(erogamesToken, whiteLabel)
      .pipe(
        switchMap((response: AuthResponse) =>
          this._afterSignUp({
            ...response,
            method: SignMethod.erogames,
          })
        )
      );
  }

  signWithNutaku(response: AuthResponse): Observable<boolean> {
    return this._afterSignUp({ ...response, method: SignMethod.nutaku });
  }

  signWithNutakuSp(): Observable<boolean> {
    return this._authApiService.signWithNutakuSp().pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.nutaku,
        })
      )
    );
  }

  signWithNutakuApkTry(): Observable<boolean> {
    return this._authApiService.signWithNutakuApkTry().pipe(
      switchMap((response: AuthResponse) =>
        this._afterSignUp({
          ...response,
          method: SignMethod.nutaku,
        })
      )
    );
  }

  private _getAndRemovePayloadData(): AuthPayloadData {
    return this._incomePayloadDataService.getAndRemovePayloadData();
  }

  public logout(): void {
    this._authStateService.authStatus = AuthStatus.notAuthenticated;
    this._tokenService.removeToken();
    localStorage.clear();
    window.location.href = '/';
  }

  public wipeOut() {
    this._authApiService.wipeOut().subscribe((res) => {
      localStorage.clear();
      alert(
        'All data was cleared. Now you will start the game from the beginning.'
      );
      window.location.href = '/';
    });
  }
}
