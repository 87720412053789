import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UnsubscribeHelper } from 'src/app/shared/helpers/unsubscribe.helper';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { SettingsDataService } from 'src/app/shared/services/comunication_services/settingsData.sevice';
import { ModalsService } from 'src/app/shared/services/modals.service';
import { OffersService } from 'src/app/shared/services/offers.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { SubscriptionStatus } from 'src/app/shared/types/user-my-response.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { SwiperOptions } from 'swiper';
import { PolicyComponent } from '../../policy/policy.component';
import { SubscriptionTermsComponent } from '../../subscriptionTerms/subscription-terms.component';
import { TermsOfUseComponent } from '../../terms-of-use/terms-of-use.component';
import { PremiumUnsubscribeModalComponent } from '../premium-unsubscribe-modal/premium-unsubscribe-modal.component';
import { SwiperComponent } from 'swiper/angular';
import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';

@Component({
  selector: 'app-premium-modal',
  templateUrl: './premium-modal.component.html',
  styleUrls: ['./premium-modal.component.scss'],
})
export class PremiumModalComponent implements OnInit, OnDestroy {
  @Input() public offerData: OfferData;
  @Input() public callFrom: string; // For Analytics only

  public subscriptionStatus: SubscriptionStatus;
  public subscriptionStatuses = SubscriptionStatus;
  public environment = environment;
  public buildVersion = BuildVersion;
  public swiperConfig: SwiperOptions = {
    width: 185,
    parallax: true,
    pagination: false,
    spaceBetween: 35,
  };
  swiperConfig2: SwiperOptions = {
    autoplay: false,
    parallax: true,
    spaceBetween: 0,
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: false,
    },
    on: {
      activeIndexChange: (e) => {
        this.step = e.activeIndex;
        this._changeDetection.detectChanges();
      },
    },
  };
  loading = false;
  public isCloseButtonEnabled = true;

  public step: number;

  @ViewChild(SwiperComponent) paywallSwiper: SwiperComponent;
  private _subscribers: Subscription[] = [];
  private priceForAnalytics = '';

  constructor(
    private _modalController: ModalController,
    private _offersService: OffersService,
    private _settingsDataService: SettingsDataService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _analyticsService: AnalyticsService,
    private _modalsService: ModalsService,
    private _legalPaymentService: LegalPaymentService,
    private _changeDetection: ChangeDetectorRef,
    private _platform: Platform
  ) {}

  public ngOnInit(): void {
    // this.isCloseButtonEnabled =
    //   this.callFrom !== 'auth' ||
    //   (this._platform.is('cordova') && this._platform.is('android'));

    this._subscribers.push(
      this._settingsDataService.updateSettingsData.subscribe((res) => {
        this.subscriptionStatus = res.subscriptionStatus;
        this._analyticsService.showPremiumAccountModalPage(
          this.subscriptionStatus,
          this.callFrom
        );

        if (
          environment.buildVersion === BuildVersion.legal &&
          this.subscriptionStatus !== SubscriptionStatus.active
        ) {
          this._legalPaymentService
            .getProductPriceByCommonId(this.offerData.purchase.productId)
            .subscribe((res1) => {
              this.priceForAnalytics = res1;
            });
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }

  public purchaseOffer(): void {
    this._subscribers.push(
      this._offersService
        .purchaseOffer(this.offerData, 'modal')
        .subscribe((res) => {
          if (res) {
            this.close(true);
          }
        })
    );
  }

  goToNext(step: number) {
    this.step = step;
    this._analyticsService.premiumModalNext(step, this.priceForAnalytics);
    this.paywallSwiper.swiperRef.slideNext();
  }

  public showUnsubscribeModal() {
    this._subscribers.push(
      this._modalsService
        .openModal(PremiumUnsubscribeModalComponent)
        .subscribe()
    );
  }

  public openPolicyModal(): void {
    this._subscribers.push(
      this._modalsService.openModal(PolicyComponent).subscribe()
    );
  }

  public openTermsOfUseModal(): void {
    this._subscribers.push(
      this._modalsService.openModal(TermsOfUseComponent).subscribe()
    );
  }

  public openSubscriptionTermsModal(): void {
    this._subscribers.push(
      this._modalsService.openModal(SubscriptionTermsComponent).subscribe()
    );
  }

  public close(action: boolean = false): void {
    this._analyticsService.premiumModalClose(this.priceForAnalytics);
    this._modalController.dismiss(action);
  }
}
