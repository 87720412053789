import { ServerMaintenanceModalService } from './server-maintenance-modal.service';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  catchError,
  delay,
  take,
  retryWhen,
  takeWhile,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../enums/auth-status.enum';
import { AuthStateService } from './auth/auth-state.service';
import { JWTTokenService } from './comunication_services/JWTToken.service';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';

export enum WebsocketConnectionType {
  send = 'send',
  invoke = 'invoke',
}

export interface WSInvokeResponse<T> {
  type: WebsocketCommandType;
  result: T;
  error?: {
    error: any;
    status: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WebsocketSignalRService {
  private _connection: signalR.HubConnection;
  private _connected = false;
  private _connectionProblemToast: BehaviorSubject<boolean> = new BehaviorSubject(false);
  timer;

  get connectionProblemToast$(): Observable<boolean> {
    return this._connectionProblemToast.asObservable();
  }

  constructor(
    private _tokenService: JWTTokenService,
    private _authStateService: AuthStateService,
    private _serverMaintenanceModalService: ServerMaintenanceModalService
  ) { }

  init(): Observable<boolean> {
    return this._authStateService.authStatus$.pipe(
      filter((status) => status === AuthStatus.authenticated),
      switchMap(() => {
        this.log('WS init sm');
        const token = this._tokenService.getToken();

        this._connection = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(environment.apiUrl + '/game', {
            accessTokenFactory: () => token,
          })
          // [0, 1, 100, 100, 100, 100, 1000, 1000, 1000, 1000, 10000, 10000, 10000]
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              if (retryContext.previousRetryCount < 10) {
                this.log('Attempt 10');
                // If we've been reconnecting for less than 60 seconds so far,
                // wait between 0 and 10 seconds before the next reconnect attempt.
                return retryContext.previousRetryCount * 100;
              } else if (retryContext.previousRetryCount < 100) {
                this.log('Attempt 100');
                // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                return 1000;
              } else {
                this.log('Attempt 1000');
                return 10000;
                //return null;
              }
            },
          })
          .build();

        this._connection.on('OnResponse', (message) => {
          this.log('response', message);
        });

        this._connection.onreconnecting((error) => {
          this.log('onreconnecting case', 'No Value');
          this.timer = setTimeout(() => {
            this._connectionProblemToast.next(true);
          }, 10000);
        });
        this._connection.onreconnected((res) => {
          clearTimeout(this.timer);
          this.log('onreconnected case', res);
          this._connectionProblemToast.next(false);
        });

        this._connection.serverTimeoutInMilliseconds = 6000;

        return this._connect();
      })
    );
  }

  private _connect(): Observable<boolean> {
    this.log('_connect', 'NO VALUE');
    return of(null).pipe(
      switchMap(() => {
        this.log('Connection.state', this._connection.state);
        switch (this._connection.state) {
          case 'Disconnected':
            return from(this._connection.start()).pipe(
              map(() => {
                this.log('WS CONNECTED', 'NO VALUE');
                this._connected = true;
                return true;
              }),
              catchError((err) => {
                // console.error('Websocket connection error', err.toString());
                throw err;
              })
            );
          case 'Connecting':
            this.log('Connecting', 'NO VALUE');
            return of(false);
          case 'Reconnecting':
            this.log('Reconnecting', 'NO VALUE');
            return of(false);
          case 'Connected':
          default:
            this.log('Connected', 'NO VALUE');
            return of(true);
        }
      })
    );
  }

  send(type: WebsocketCommandType, command): Observable<any> {
    this.log('WS action: Send', 'NO VALUE');
    return this._connect().pipe(
      filter((res) => res === true),
      switchMap(() => from(this._connection.send('Send', { type, command })))
    );
  }

  invoke<ResultType>(
    type: WebsocketCommandType,
    command
  ): Observable<ResultType> {
    this.log('WS action: Invoke NO VALUE. type: ', type);
    this.log('WS action: Invoke NO VALUE. command: ', command);
    return this._connect().pipe(
      map((res) => {
        this.log('WS action: Invoke map', res);
        if (!res) {
          // eslint-disable-next-line no-throw-literal
          throw 'noConnection';
        }
        return res;
      }),
      retryWhen((errors) =>
        errors.pipe(
          takeWhile((error) => {
            this.log('WS action: Invoke retryWhen takeWhile', error);
            if (this._connected) {
              // this._connectionProblemToast.next(true);
            }
            this.log('RETRY WHEN ERROR', error);
            return true;
          }),
          delay(500)
        )
      ),
      switchMap(() =>
        from(this._connection.invoke('Execute', { type, command })).pipe(
          mergeMap((response: WSInvokeResponse<ResultType>) => {
            this.log('WS action: Invoke switchMap', response);
            switch (response.type) {
              case WebsocketCommandType.error:
                if (response.error.status === 'serverMaintenance.') {
                  this._serverMaintenanceModalService.showModal();
                }
                return throwError(response);
              default:
                this._connectionProblemToast.next(false);
                return of(response.result);
            }
          }),
          // retryWhen((errors) =>
          //   errors.pipe(
          //     takeWhile((error) => {
          //       console.log('WS action: Invoke retryWhen takeWhile', error);
          //       this._connectionProblemToast.next(true);
          //       console.log('RETRY WHEN ERROR', error);
          //       return true;
          //     }),
          //     delay(500)
          //   )
          // ),
          catchError((err) => {
            console.error('WS REQUEST ERROR', err);
            this._connect().subscribe((res) => {
              console.error('Check ws connection', res);
            });
            return throwError(err);
          })
        )
      ),
      tap((res) => {
        this.log('Invoke res', res);
      })
    );
  }

  private log(description: string, value: any = undefined) {
    if (localStorage.getItem('showlogs')) {
      console.log(description, value);
    }
  }
}
