<!-- Костыль https://bitbucket.org/angrybangersteam/getmynudes_client/commits/dad836f0e443b63c7a2203e9941780d95985114e -->
<div class="ios-spacer" *ngIf="isTopSpacer"></div>

<ion-header id="header" role="heading" class="ion-no-border">
  <div
    class="left-spacer"
    [class.profile-header]="
      (state[uiStatesEnum.avatarUrl] &&
        state[uiStatesEnum.state] === stateEnum.chat) ||
      state[uiStatesEnum.state] === stateEnum.album
    "
  >
    <button
      class="back-btn"
      *ngIf="
        state[uiStatesEnum.state] === stateEnum.chat ||
        state[uiStatesEnum.state] === stateEnum.album ||
        state[uiStatesEnum.state] === stateEnum.profile
      "
      (click)="back()"
    >
      <img src="/assets/img/header/back-btn.svg" alt="" />
    </button>
  </div>
  <div
    class="title-wrapper"
    (click)="playPing1()"
    *ngIf="
      state[uiStatesEnum.state] !== stateEnum.chat &&
      state[uiStatesEnum.state] !== stateEnum.album
    "
  >
    <h1 class="page-title">
      <a
        *ngIf="subscriptionStatus === subscriptionStatuses.active"
        (click)="openPremiumModal()"
        class="premium-icon"
      ></a>

      <ng-container *ngIf="state.state === stateEnum.main">
        {{ "CHATS_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.gallery">
        {{ "GALLERY_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container
        *ngIf="
          state.state === stateEnum.bonus ||
          state.state === stateEnum.questLines ||
          state.state === stateEnum.promoCode
        "
        >{{ "BONUS_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.shop">
        {{ "SHOP_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.settings">
        {{ "SETTINGS_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.swipe">
        {{ "SWIPE_PAGE.TITLE" | translate }}
      </ng-container>
      <ng-container *ngIf="state.state === stateEnum.profile">
        {{ state.characterName }}
      </ng-container>
    </h1>
  </div>

  <div
    class="profile"
    *ngIf="
      (state[uiStatesEnum.avatarUrl] &&
        state[uiStatesEnum.state] === stateEnum.chat) ||
      state[uiStatesEnum.state] === stateEnum.album
    "
  >
    <a (click)="gotoProfile(state[uiStatesEnum.chatId])" class="avatar">
      <img
        [attr.src]="state[uiStatesEnum.avatarUrl]"
        alt="girl"
        class="avatar"
        *ngIf="state[uiStatesEnum.avatarUrl] !== ''"
      />
    </a>

    <div class="name-box">
      <p class="name">{{ state[uiStatesEnum.characterName] }}</p>
      <div
        *ngIf="state[uiStatesEnum.state] === stateEnum.chat"
        class="status"
        [class.status-online]="!state[uiStatesEnum.isOffline]"
        [class.status-offline]="state[uiStatesEnum.isOffline]"
      >
        {{
          (state[uiStatesEnum.isOffline]
            ? "PROFILE_PAGE.OFFLINE"
            : "PROFILE_PAGE.ONLINE"
          ) | translate
        }}
      </div>
    </div>
  </div>

  <app-money-counter></app-money-counter>

  <app-chat-rating-bar
    *ngIf="state[uiStatesEnum.state] === stateEnum.chat"
  ></app-chat-rating-bar>
</ion-header>
