<div
  class="pcb-container slider-modal see-five-likes"
  *ngIf="appearanceType === 'modal'"
>
  <div class="offer-banner">
    <div class="banner-top">
      <h2 *ngIf="!purchased" [innerHTML]="'OFFERS.FIVE_LIKES.SEE_5_GIRLS' | translate">

      </h2>
      <h2 *ngIf="purchased" [innerHTML]="'OFFERS.FIVE_LIKES.5_GIRLS_LIKED_YOU' | translate">
      </h2>
      <div class="pcb-icons-box">
        <div
          class="pcb-icon"
          [ngClass]="'pcb-icon-' + (i + 1)"
          *ngFor="let chat of offerData.chats; let i = index"
        >
          <img [src]="chat.imageUrl" [ngClass]="{ unblurredd: purchased }" />
        </div>
      </div>
    </div>
    <div class="banner-bottom">
      <p *ngIf="!purchased" [innerHTML]="'OFFERS.FIVE_LIKES.AND_GET_ACCESS' | translate">
      </p>

      <app-price-block
        [offerData]="offerData"
        *ngIf="!purchased"
      ></app-price-block>
      <button
        class="btn btn-purchased"
        style="margin: 20px; width: calc(100% - 40px)"
        (click)="goToChats()"
        *ngIf="purchased"
      >
        {{"OFFERS.FIVE_LIKES.GO_TO_CHATS" | translate}}
      </button>
    </div>
  </div>
</div>
