import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChatDataService } from '../../services/comunication_services/chatData.service';

@Component({
  selector: 'app-chat-rating-bar',
  templateUrl: './chat-rating-bar.component.html',
  styleUrls: ['./chat-rating-bar.component.scss'],
})
export class ChatRatingBarComponent implements OnInit {
  chatRating = 0;
  chatLevelProgress = 0;
  rating$: Observable<number>;
  level = null;
  levelUp = false;
  heartIcon: AnimationItem;
  public environment = environment;
  public isTutorialActive = false;
  public tutorialStep = 0;
  public firstTutorialStepPopupOffset = '0px';
  private readonly _tutorialLocalStorageKey = 'isChatRatingTutorialShown';
  private readonly _tutorialActiveClass = 'chat-reputation-tutorial';
  private _isRatingInitialized = false;

  saveToGalleryAnimationOptions: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/level-up/level-up.json`,
    loop: false,
  };

  heartRateAnimationOptins: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/heart-rate.json`,
    loop: false,
  };

  constructor(private _chatDataService: ChatDataService) {}

  ngOnInit() {
    this.rating$ = this._chatDataService.updateBlockData.pipe(
      map((res) => {
        if (this.level !== null && this.level < res.chatLevel) {
          this.showLevelUp();
        }

        if (this.chatRating !== null && this.chatRating < res?.chatRating) {
          this.showRatingUp();
        }

        this.level = res.chatLevel;

        if (
          this.chatRating !== res.chatRating &&
          this.chatRating === 0 &&
          this._isRatingInitialized &&
          !localStorage.getItem(this._tutorialLocalStorageKey)
        ) {
          this.showTutorial();
        }

        this.chatRating = res.chatRating;
        this.chatLevelProgress = res.chatLevelProgress;

        if (!this._isRatingInitialized) {
          this._isRatingInitialized = true;
        }

        return 100 - this.chatLevelProgress;
      })
    );
  }

  heartAnimationCreate(el) {
    this.heartIcon = el;
  }

  showLevelUp() {
    this.levelUp = true;
    setTimeout(() => {
      this.levelUp = false;
    }, 2000);
  }

  showRatingUp() {
    this.heartIcon?.playSegments([0, 120], true);
  }

  private showTutorial(): void {
    const popupWidth = 215;

    setTimeout(() => {
      document.body.classList.add(this._tutorialActiveClass);

      this.isTutorialActive = true;
      const headerRect = document
        .getElementById('header')
        .getBoundingClientRect();
      const parentRect = document
        .getElementById('chat-rating-value')
        .getBoundingClientRect();

      if (headerRect.x + headerRect.width - parentRect.x > popupWidth) {
        this.firstTutorialStepPopupOffset = '-10px';
      } else if (parentRect.x - headerRect.x > popupWidth) {
        this.firstTutorialStepPopupOffset = `-${popupWidth - 20}px`;
      } else {
        this.firstTutorialStepPopupOffset = `-${
          popupWidth - (headerRect.x + headerRect.width - parentRect.x)
        }px`;
      }
    }, 1000);
  }

  public nextTutorialStep(): void {
    this.tutorialStep = 1;
  }

  public completeTutorial(): void {
    document.body.classList.remove(this._tutorialActiveClass);

    this.isTutorialActive = false;
    localStorage.setItem(this._tutorialLocalStorageKey, 'true');
  }
}
