import { AuthErogamesService } from './auth-erogames.service.ts.service';
import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of, pipe, timer } from 'rxjs';
import { NavHelper } from '../../helpers/nav.helper';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';
import { AuthStateService } from './auth-state.service';
import {
  catchError,
  delay,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { AuthMethodsService } from './auth-methods.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { AuthNutakuService } from './auth-nutaku.service';
import { AuthPayload } from '../../enums/auth-payload.enum';
import { OffersModalService } from '../offers-modal.service';
import { SettingsDataService } from '../comunication_services/settingsData.sevice';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { ShopTokensService } from '../../views/shop-tokens/shop-tokens.service';
import { DailyQuestsService } from '../../views/daily-quests/daily-quests.service';
import { BonusService } from '../API_services/bonus.service';
import { ShopBoostersService } from '../../views/shop-boosters/shop-boosters.service';
import { ShopPacksService } from '../../views/shop-packs/shop-packs.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isPremiumModalShown = false;

  constructor(
    private _tokenService: JWTTokenService,
    private _navHelper: NavHelper,
    private _authStateService: AuthStateService,
    private _authErogamesService: AuthErogamesService,
    private _authNutakuService: AuthNutakuService,
    private _authMethodsService: AuthMethodsService,
    private _offersModalService: OffersModalService,
    private _settingsDataService: SettingsDataService,
    //preload
    private _shopTokensService: ShopTokensService,
    private _shopBoostersService: ShopBoostersService,
    private _shopPacksService: ShopPacksService,
    private _dailyQuestsService: DailyQuestsService,
    private _bonusService: BonusService
  ) {
    if (environment.buildVersion === BuildVersion.erogames) {
      this._tokenService.erogamesToken = null;
    }
  }

  ///////////////

  private _changeRegistrationVisibility(isShow: boolean): void {
    console.log('change reg', isShow);
    if (environment.allowRegistrationForm) {
      console.log('allow');
      if (isShow) {
        console.log('is Show');
        this._authStateService.showRegistrationForm({
          isShow: true,
          data: {
            placeWereCalled: 'Auth At Start',
            enableClosing: false,
          },
        });
      } else {
        this._authStateService.hideRegistrationForm(false);
      }
    }
  }

  private _isAuthorisedByBuildVersion(): boolean {
    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        return !!this._tokenService.erogamesToken;
      case BuildVersion.legal:
        // LEGAL TEST HACK @Todo
        return !!(
          this._tokenService.getUserEmail() &&
          !this._tokenService.isTokenExpired()
        );
      // return true;
      case BuildVersion.nutaku:
        return false;
      case BuildVersion.default:
        return !!(
          this._tokenService.getUserEmail() &&
          !this._tokenService.isTokenExpired()
        );
    }
  }

  private _showRegform() {
    // alert('show reg form');
    this._navHelper.goToLaunch();
    console.log(
      '!dd',
      !environment.showTncs || localStorage.getItem('read_tncs')
    );
    if (!environment.showTncs || localStorage.getItem('read_tncs')) {
      console.log('change reg');
      this._changeRegistrationVisibility(true);
    }
  }

  private _tryAsGuestABTestOrShowRegform() {
    const uspidntfr = localStorage.getItem(AuthPayload.uspidntfr);
    const usptoken = localStorage.getItem(AuthPayload.usptoken);

    // Check if it's switching from WEB to APK
    const authMethod =
      uspidntfr && usptoken
        ? this._authMethodsService.signBySwitchTokenApk()
        : this._authMethodsService.trySignAsGuest();

    authMethod
      .pipe(
        catchError(() => {
          this._showRegform();
          return of(null);
        })
      )
      .subscribe();
  }

  private authenticationProcess(): Observable<boolean> {
    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        this._authErogamesService.erogamesTokenUpdateOnRefresh();
        break;
      case BuildVersion.legal:
        // LEGAL TEST HACK @Todo
        // this._showRegform();
        this._tryAsGuestABTestOrShowRegform();
        break;
      case BuildVersion.nutaku:
        this._authNutakuService.auth();
        // this._tryAsGuestABTestOrShowRegform();
        break;
      case BuildVersion.default:
        this._tryAsGuestABTestOrShowRegform();
        break;
    }

    return this._authStateService.authStatus$.pipe(
      filter((res) => res === AuthStatus.authenticated),
      map(() => true),
      take(1)
    );
  }

  authorise(): Observable<any> {
    return this._authStateService.authStatus$.pipe(
      filter((res) => res !== AuthStatus.inProgress),
      take(1),
      switchMap((res) => {
        if (res === AuthStatus.authenticated) {
          // alert('Authed');
          return from(this._authMethodsService.getUser()).pipe(map(() => true));
        } else if (this._isAuthorisedByBuildVersion()) {
          // alert('_isAuthorisedByBuildVersion');
          this._authStateService.authStatus = AuthStatus.authenticated;
          return from(this._authMethodsService.getUser()).pipe(map(() => true));
        } else {
          // alert('auth else');
          this._authStateService.authStatus = AuthStatus.inProgress;
          return this.authenticationProcess();
        }
      }),
      switchMap((res) =>
        forkJoin([
          this._shopTokensService.fetchTokens(),
          this._shopBoostersService.fetchBoosters(),
          this._dailyQuestsService.fetchQuests(),
          this._bonusService.fetchQuestLines(),
          this._shopPacksService.fetchPacks(),
        ]).pipe(map(() => res))
      ),
      switchMap((res) => {
        if (
          !this._isPremiumModalShown &&
          environment.buildVersion === BuildVersion.legal &&
          this._settingsDataService.updateSettingsData.value
            .subscriptionStatus !== SubscriptionStatus.active &&
          this._settingsDataService.updateSettingsData.value.config
            .paywallShowOnStart
        ) {
          this._isPremiumModalShown = true;
          return this._offersModalService.showSubscriptionOffer('auth');
        }
        return of(res);
      })
    );
  }
}
