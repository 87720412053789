import { Observable } from 'rxjs';
import { PaymentsService } from './payments/payments.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { PurchaseItemType } from '../enums/purchase-item-type.enum';
import { OfferData } from '../types/offer-data.interface';
import { RealPurchaseData } from '../types/purchase-data.interface';
import { ShopType } from '../enums/shop-type.enum';
import { WebsocketSignalRService } from './websocket-signalr.service';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(
    private _paymentsService: PaymentsService,
    private _websocketSignalRService: WebsocketSignalRService
  ) {}

  purchaseOffer(
    offerData: OfferData,
    appearanceType: string
  ): Observable<boolean> {
    const data: RealPurchaseData = {
      source:
        appearanceType === 'modal'
          ? PurchaseItemType.offerModal
          : PurchaseItemType.offerBanner,
      purchase: offerData.purchase,
      productId: offerData.purchase.productId,
    };
    if (environment.buildVersion === BuildVersion.nutaku) {
      data.nutakuData = {
        nutakuName: 'Offer',
        nutakuDescription: '',
        nutakuImageUrl:
          'https://nutaku.getmynudes.com/assets/img/logo-gmn-for-nutaku.png',
      };
    }
    return this._paymentsService.purchase(data);
  }

  public getSubscriptionOffer() {
    return this._websocketSignalRService.invoke<OfferData>(
      WebsocketCommandType.getSubscriptionOffer,
      {}
    );
  }
}
