import { RealCostProperties } from './purchase-data.interface';
import { UnbanPurchase } from './open-next-message.interface';
import { PromoBrand } from '../enums/chat-promo-brand.enum';
import { NextMessage } from './next-message.interface';
import { Language } from '../enums/language.enum';

export enum ChatType {
  free = 'Free',
  real = 'Real',
  soft = 'Soft',
  promo = 'promo',
  discordLink = 'discordLink',
  trial = 'Trial',
  noMessagesBadge = 'noMessagesBadge',
}

export interface OpenChatData {
  type: ChatType;
  characterName: string;
  id: string;
  chatLotId: string;
  trialPurchase: RealCostProperties;
  subscriptionPurchase?: RealCostProperties;
  avatarUrl: string;
  promoCompany?: PromoBrand;
}
export interface ChatData {
  id: string;
  locale: Language | null;
  characterAvatar: string;
  characterName: string;
  messages: NextMessage[];
  newMessageCount?: number;
  chatLotId?: string;
  isOffline?: boolean;
  offlineSkipCost?: number;
  offlineTime?: number;
  isAdditionalFinished?: boolean;
  isAdditionalPurchased?: boolean;
  isFeedbackSent?: boolean;
  isFinished?: boolean;
  chatRating?: number;
  hasAdditionalContent?: boolean;
  isBanned?: boolean;
  chatLevel?: number;
  chatLevelProgress?: number;
  trialTime?: number;
  status: string;
  trialPurchase?: RealCostProperties;
  softPurchase?: RealCostProperties;
  subscriptionPurchase?: RealCostProperties;
  feedbackFormShow?: boolean;
  promoWidget?: string;
  promoCompany?: PromoBrand;
  promoHasDetails?: boolean;
  promoDetailsText?: string;
  promoThumbnailPhotos?: string[];
  promoBanner?: PromoBrand;
  type?: ChatType;
  unbanPurchase?: UnbanPurchase;
  isOnlineOnStripChat?: boolean;
}
