import { PaymentsStateService } from 'src/app/shared/services/payments/payments-state.service';
import { Injectable } from '@angular/core';
import {
  IAPProduct,
  IAPProducts,
  InAppPurchase2,
} from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { HttpClient } from '@angular/common/http';
import { Platform, ToastController } from '@ionic/angular';
import {
  EMPTY,
  interval,
  Observable,
  of,
  race,
  Subject,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  first,
  last,
  map,
  mergeMap,
  retry,
  retryWhen,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { RealPurchaseData } from '../../types/purchase-data.interface';
import { environment } from 'src/environments/environment';
import { StoreItemType } from '../../enums/store-Item-type.enum';
import { WebsocketSignalRService } from '../websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { PaymentsCommonMethodsService } from './payments-common-methods.service';
import { Logger, LoggingService } from 'ionic-logging-service';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';
import { AnalyticsService } from '../analytics/analytics.service';
export interface StoreProduct {
  appleStoreId: string;
  coins: number;
  cost: number;
  discount: number;
  googlePlayId: string;
  lotType: string;
  offerType: string;
  oldCoins: number;
  oldCost: number;
  productId: string;
  productType: StoreItemType;
  isOwned: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class LegalPaymentService {
  products: IAPProducts;
  private _host: string = environment.apiUrl;
  private _storeItemsInfo: IAPProduct[] = [];
  private _ownedProducts = new Set<string>();
  private _ownedProducts$: Subject<{ id: string; ok: boolean }> = new Subject();
  logger: Logger;
  existsPriceSend = false;
  noPriceSend = false;
  constructor(
    private _platform: Platform,
    private store: InAppPurchase2,
    private _http: HttpClient,
    private _signalRService: WebsocketSignalRService,
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService,
    private _logginService: LoggingService,
    private _paymentsStateService: PaymentsStateService,
    private _analyticsService: AnalyticsService,
    private _toastCtrl: ToastController
  ) {
    this.logger = this._logginService.getLogger('App.LegalPaymentService');
  }

  get storeItemsInfo(): IAPProduct[] {
    return this._storeItemsInfo;
  }

  private _getCurrentStoreId(
    googlePlayId: string,
    appleStoreId: string
  ): string {
    return this._platform.is('android')
      ? googlePlayId
      : this._platform.is('ios')
      ? appleStoreId
      : null;
  }

  getProductPriceByCommonId(id: string): Observable<string> {
    return of(null).pipe(
      switchMap(() => this.getItemPrice(id)),
      retryWhen((errors) => {
        let retries = 0;
        return errors.pipe(
          tap(() => {
            retries++;
            if (retries > 10) {
              throw new Error('Max retries exceeded');
            }
          }),
          delay(1000)
        );
      })
    );
  }

  private getItemPrice(id: string): Observable<string> {
    console.log('Get price', id);


    const item = this.storeItemsInfo.find((i) => i.id === id);
    this._analyticsService.serverLogging('GetPrice: ', {id, item});
    if (item && item.price) {
      if (!this.existsPriceSend) {
        this._analyticsService.androidDevTool('price_exists', { id });
        this.existsPriceSend = true;
      }
      return of(item.price);
    } else {
      this.refreshStoreSmartly();
      if (!this.noPriceSend) {
        this._analyticsService.androidDevTool('no_price', { id });
        this.noPriceSend = true;
      }
      return throwError('No price');
    }
  }

  lastTimeRefreshStore = 0;

  refreshStoreSmartly() {
    const now = Date.now();
    if (now - this.lastTimeRefreshStore >= 2000) {
      this.lastTimeRefreshStore = now;
      setTimeout(() => {
        this.store.refresh();
      }, 2000);
    }
  }

  init() {
    this._platform.ready().then(() => {
      this._addListeners();
      this._signalRService
        .invoke(WebsocketCommandType.getStoreLots, {})
        .pipe(take(1))
        .subscribe((products: StoreProduct[]) => {
          // alert('Subs: ' + JSON.stringify(products));
          try {
            // this.store.verbosity = 1;
            this.store.log.info('Init');
            this.store.validator = (product: IAPProduct, callback) => {
              // if (product.id === environment.appId) {
              //   product.finish();
              //   return;
              // }
              // alert('store callback: ' + JSON.stringify(product));
              const requestUrl = `${this._host}/api/Shop/verify`;
              this._http
                .post<any>(requestUrl, { product })
                .pipe(take(1))
                .subscribe((res) => {
                  callback(res);
                });
            };

            this.store.register(
              products
                .filter((p) => !!p.googlePlayId)
                .map((product) => {
                  if (
                    product.isOwned &&
                    product.productType !== StoreItemType.consumable
                  ) {
                    this._ownedProducts.add(product.googlePlayId);
                  }
                  let type = '';
                  switch (product.productType) {
                    case StoreItemType.nonConsumable:
                      type = this.store.NON_CONSUMABLE;
                      break;
                    case StoreItemType.paidSubscription:
                      type = this.store.PAID_SUBSCRIPTION;
                      break;
                    case StoreItemType.consumable:
                    default:
                      type = this.store.CONSUMABLE;
                      break;
                  }
                  return {
                    id: this._getCurrentStoreId(
                      product.googlePlayId,
                      product.appleStoreId
                    ),
                    type,
                    platform: this._platform.is('ios') ? 'ios-appstore' : 'android-playstore'
                  };
                })
            );
            this.store.refresh();
          } catch (error) {
            //alert('payment init err: ' + JSON.stringify(error));
            console.log('legal payments init error', error);
          }
        });
    });
  }

  private _addListeners() {
    try {
      this.store
        .when('product')
        .approved((approvedData: IAPProduct) => {
          if (approvedData.id === environment.appId) {
            return;
          }
          this._analyticsService.serverLogging('Approved', approvedData);
          this.store.log.info('approved: ' + JSON.stringify(approvedData));
          //alert('approved: ' + JSON.stringify(approvedData));
          console.log('approved: ' + JSON.stringify(approvedData));
          return approvedData.verify();
        })
        .verified((verifiedData: IAPProduct) => {
          if (verifiedData.id === environment.appId) {
            return;
          }
          this._analyticsService.serverLogging('Verified', verifiedData);
          //alert('verifiedData: ' + JSON.stringify(verifiedData));
          this.store.log.info('verifiedData: ' + JSON.stringify(verifiedData));
          console.log('verifiedData: ' + JSON.stringify(verifiedData));
          return verifiedData.finish();
        })
        .expired((data: IAPProduct) => {
          //alert('Expired:' + JSON.stringify(data));
          this._analyticsService.serverLogging('Expired', data);
          this.store.log.info('Expired:' + JSON.stringify(data));
          const requestUrl = `${this._host}/api/Shop/expired`;
          this._http.post<any>(requestUrl, { data }).pipe(take(1)).subscribe();
        })
        // .owned((data: IAPProduct) => {
        //   if (data.id === environment.appId) {
        //     return data.finish();
        //   }
        //   // this._analyticsService.legalShopping('Owned', data);
        //   return data.finish();
        // })
        .finished((data: IAPProduct) => {
          this._analyticsService.serverLogging('Finished', data);
          // Hack for IOS issue
          //alert('Finished: ' + JSON.stringify(data?.id));
          this.store.log.info('Finished: ' + JSON.stringify(data?.id));
          if (data.id === environment.appId) {
            return;
          }
          // Product had already beed bought and dont need to show no info
          if (this._ownedProducts.has(data.id)) {
            return;
          }
          if (data.type !== this.store.CONSUMABLE) {
            this._ownedProducts.add(data.id);
          }
          this._ownedProducts$.next({ id: data.id, ok: true });
          const id = data.transaction?.id;
          const lotId = data.id;
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.progress;
          const requestUrl = `${this._host}/api/Shop/store-payment-response?id=${id}&lotId=${lotId}`;
          this._http
            .get<any>(requestUrl)
            .pipe(take(1))
            .subscribe({
              next: (res) => {
                this._paymentsCommonMethodsService.checkTransactionHandler(res);
              },
              error: (err) => {
                //('store-payment-response err:' + JSON.stringify(err));
              },
            });
        })
        // .refunded((data: IAPProduct) => {
        //   const requestUrl = `${this._host}/api/Shop/refunded`;
        //   this._http.post<any>(requestUrl, { data }).pipe(take(1)).subscribe();
        // })
        // .unverified((data: IAPProduct) => {
        //   this._analyticsService.legalShopping('Unverified', data);
        //   //alert('Unverified: ' + data);
        //   this.store.log.info('Unverified: ' + data);
        // })
        .registered((registeredData: IAPProduct) => {
          this._analyticsService.serverLogging('Registered', registeredData);
          //alert('Registered: ' + registeredData);
          this.store.log.info('Registered: ' + registeredData);
          console.log('registeredData', registeredData);
          this._storeItemsInfo.push(registeredData);
        })
        .error((data: IAPProduct) => {
          this._analyticsService.serverLogging('Error', data);
          this._paymentsStateService.paymentState = null;
          //alert('Error:' + JSON.stringify(data));
          this.store.log.info('Error:' + JSON.stringify(data));
          console.log('err data id', data);
          this._ownedProducts$.next({ id: data.id || 'error', ok: false });
        })
        .cancelled((data: IAPProduct) => {
          this._analyticsService.serverLogging('Cancelled', data);
          //alert('Cancelled:' + JSON.stringify(data));
          this.store.log.info('Cancelled:' + JSON.stringify(data));
          console.log('cancelled', data);
          this._paymentsStateService.paymentState = null;
          this._ownedProducts$.next({ id: data.id, ok: false });
        });
      // .loaded((data: IAPProduct) => {
      //   this._analyticsService.legalShopping('Loaded', data);
      //   //alert('loaded:' + JSON.stringify(data));
      //   this.store.log.info('loaded:' + JSON.stringify(data));
      //   console.log('loaded', data);
      // })
      // .updated((data: IAPProduct) => {
      //   this._analyticsService.legalShopping('Updated', data);
      //   //alert('updated:' + JSON.stringify(data));
      //   // this.store.log.info('updated:' + JSON.stringify(data));
      //   console.log('updated', data);
      // })
      // .valid((data: IAPProduct) => {
      //   this._analyticsService.legalShopping('Valid', data);
      //   //alert('valid:' + JSON.stringify(data));
      //   this.store.log.info('valid:' + JSON.stringify(data));
      //   console.log('valid', data);
      // })
      // .invalid((data: IAPProduct) => {
      //   this._analyticsService.legalShopping('Invalid', data);
      //   // alert('invalid:' + JSON.stringify(data));
      //   console.log('invalid', data);
      // })
      // .requested((data: IAPProduct) => {
      //   this._analyticsService.legalShopping('Requested', data);
      //   //alert('requested:' + JSON.stringify(data));
      //   this.store.log.info('requested:' + JSON.stringify(data));
      //   console.log('requested', data);
      // })
      // .initiated((data: IAPProduct) => {
      //   this._analyticsService.legalShopping('Initiated', data);
      //   //alert('initiated:' + JSON.stringify(data));
      //   this.store.log.info('initiated:' + JSON.stringify(data));
      //   console.log('initiated', data);
      // })
      // .downloaded((data: IAPProduct) => {
      //   // alert('downloaded:' + JSON.stringify(data));
      //   console.log('downloaded', data);
      // })
    } catch (error) {
      // alert('Catch store err: ' + error);
    }
  }

  private _waitUntilCanPurchase(id: string): Observable<boolean> {

    return of(true);

    // TEMPORARY DISABLED
    const item = this.storeItemsInfo.find((i) => i.id === id);
    if (item?.canPurchase) {

      this._toastCtrl
                .create({
                  message: 'You can purchase this item',
                  duration: 400,
                })
                .then((res) => res.present());
      return of(true);
    }

    this._toastCtrl
                .create({
                  message: 'You cannot purchase this item',
                  duration: 400,
                })
                .then((res) => res.present());

    return throwError('Cannot Purchase');
  }

  purchase(data: RealPurchaseData): Observable<boolean> {
    //alert('click purchase');
    const id = this._getCurrentStoreId(
      data.purchase.googlePlayId,
      data.purchase.appleStoreId
    );
    this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

    return of(null).pipe(
      switchMap(() => this._waitUntilCanPurchase(id)),
      retryWhen((errors) => {
        let retries = 0;
        return errors.pipe(
          tap(() => {
            retries++;
            if (retries > 10) {
              this._paymentsStateService.paymentState =
                PaymentAnimationStates.error;
              this._toastCtrl
                .create({
                  message: 'Please try again',
                  duration: 1000,
                })
                .then((res) => res.present());
              throw new Error('Max retries exceeded');
            }
          }),
          delay(500)
        );
      }),
      switchMap(() => {
        try {
          this.store.order(id).then(
            (res) => {
              this._analyticsService.serverLogging('Orderthedata: ', res);
              //alert('click purchase then: ' + JSON.stringify(res));
            },
            (err) => {
              //alert('purchase error: ' + JSON.stringify(err));
              this._paymentsStateService.paymentState =
                PaymentAnimationStates.error;
            }
          );
          return this._ownedProducts$.pipe(
            filter((product) => {
              console.log('FILTER', product);
              return product.id === id || product.id === 'error';
            }),
            take(1),
            map((product) => product.ok)
          );
        } catch (error) {
          //alert('purchase err: ' + error);
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.error;
          return of(false);
        }
      })
    );
  }

  // To comply with AppStore rules
  restore() {
    this.store.refresh();
  }

  async presentAlert(header, message) {
    alert(header + ':  ' + message);
  }
}
