import { UserMyResponse } from './../../types/user-my-response.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from '../API_services/settings.service';
import settings from '../../data/settings';
import { SubscriptionStatus } from '../../types/user-my-response.interface';
import { Language } from '../../enums/language.enum';

@Injectable({
  providedIn: 'root',
})
export class SettingsDataService {
  private data: UserMyResponse;

  public serverLogs = false;

  private defaultData: UserMyResponse = {
    avatar: '',
    email: '',
    id: '',
    music: false,
    isGuest: null,
    nickName: '',
    coins: 0,
    isNickNameSet: false,
    isAgeConfirmed: false,
    isAgreementAccepted: false,
    sound: false,
    quickMessaging: settings.quickMessaging,
    onlineStreamNotifications: settings.onlineStreamNotifications,
    subscriptionStatus: SubscriptionStatus.none,
    serverPlatform: null,
    ve: 0,
    vd: 0,
    vn: 0,
    vg: 0,
    va: 0,
    tutorialIsPassed: null,
    tutorialStep: null,
    lang: Language.english,
    config: {
      serverLogs: false,
      paywallShowOnCards: false,
      paywallShowOnStart: false
    }
  };
  public clientId = '';
  public updateSettingsData: BehaviorSubject<UserMyResponse>;
  public nicknameIsSet = false;

  constructor(private _settingsService: SettingsService) {
   this.updateSettingsData = new BehaviorSubject(this.defaultData);
  }

  public changeData(fieldName: string, value: any): void {
    const defaultData = this.setNewData(this.data, fieldName, value);
    this.data = defaultData;
    this.updateSettingsData.next(defaultData);
    this._settingsService.updateSettings({
      music: this.data.music,
      sound: this.data.sound,
      nickname: this.data.nickName,
      quickMessaging: this.data.quickMessaging,
      onlineStreamNotifications: this.data.onlineStreamNotifications,
      subscriptionStatus: this.data.subscriptionStatus,
    });
  }

  public setNewData(obj: any, field: string, value: any): any {
    const setPath = (object: any, path: any, value: any) =>
      path
        .split('.')
        .reduce(
          (o: any, p: any, i: any) =>
            (o[p] = path.split('.').length === ++i ? value : o[p] || {}),
          object
        );
    setPath(obj, field, value);
    return obj;
  }

  public changeAllObject(value: any): void {
    this.data = value;
    this.updateSettingsData.next(value);
  }

  public cleanData(): void {
    this.data = this.defaultData;
    this.updateSettingsData.next(this.data);
  }
}
