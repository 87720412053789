import { Capacitor } from '@capacitor/core';
import { ToastOnlineStreamData } from './../comunication_services/online-stream-notifications.service';
import {
  SubscriptionStatus,
  UserNotification,
} from './../../types/user-my-response.interface';
/* eslint-disable @typescript-eslint/naming-convention */
import { SwipingCardType } from './../API_services/swiping-cards.interface';
import { PromoAnalyticsData } from 'src/app/shared/types/show-promo-analytics-options.interface';
import { TutorialService } from './../comunication_services/tutorial.service';
import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import amplitude from 'amplitude-js';
import { environment } from 'src/environments/environment';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { SettingsDataService } from '../comunication_services/settingsData.sevice';
import { filter, take } from 'rxjs/operators';
import { SignMethod } from '../../enums/sign-method.enum';
import { RealPurchaseData } from '../../types/purchase-data.interface';
import { App } from '@capacitor/app';
import { ShowRegistrationFormData } from '../auth/show-registration-form-data';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';
import { OfferData } from '../../types/offer-data.interface';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { NextMessage } from '../../types/next-message.interface';
import { AudioMessageAnalyticsEvent } from '../../enums/audio-message-analytics-event';
import { Language } from '../../enums/language.enum';
import { ReasonType } from '../../enums/reason-type.enum';
// eslint-disable-next-line @typescript-eslint/naming-convention
import {
  Appmetrica,
  UserProfile,
  ProfileAttribute,
  UserProfileUpdate,
} from 'capacitor-appmetrica';
import { AdPlace } from '../admob.service';
import { DailyQuestType } from '../../views/daily-quests/daily-quests.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private isGameStarted = false;
  private isInit = false;
  buildVersion = environment.buildVersion;
  idleOnWebsiteTracker;
  idleSecondsCount = 0;
  messageLimit = 0;
  serverLogs = false;
  private _host: string = environment.apiUrl;
  constructor(
    private _platform: Platform,
    // private ga: GoogleAnalytics,
    // private ym: NgxMetrikaService,
    private _tutorialService: TutorialService,
    private _tokenService: JWTTokenService,
    private _settingsDataService: SettingsDataService,
    private appmetrica: Appmetrica,
    private _http: HttpClient,
    private _toastCtrl: ToastController
  ) {}

  public init(): void {
    if (this.isInit) {
      return;
    }
    this.isInit = true;
    const amplitudeId =
      environment.buildVersion === BuildVersion.nutaku
        ? 'edb5cc6a9627510a1b10c0387ecc91c3'
        : environment.buildVersion === BuildVersion.legal
        ? '167e27ea1dbff51e65e3081244eaccc4'
        : '11fb2e90e0cf1533c79d138072942a5a';

    amplitude.getInstance().init(amplitudeId); // initializes default instance of Amplitude client
    // this.sendEvent('EVENT_NAME_HERE');

    // initializes named instance of Amplitude client
    // const instance2 = amplitude.getInstance("instance").init("11fb2e90e0cf1533c79d138072942a5a");
    if (this._platform.is('hybrid')) {
      App.getInfo().then((res) => {
        const prefix = this.buildVersion;
        amplitude.getInstance().setVersionName(prefix + ': ' + res.build);
      });
    }

    const identify = new amplitude.Identify().setOnce('traffic');
    // this.ga
    //   .startTrackerWithId('3037886580')
    //   .then(() => {
    //     this.ga.trackView('test');
    //     // Tracker is ready
    //     // You can now track pages or set additional information such as AppVersion or UserId
    //   })
    //   .catch((e) => console.log('Error starting GoogleAnalytics', e));
    this.appMetricaInitialization();

    this._settingsDataService.updateSettingsData.subscribe((res) => {
      this.serverLogs = res.config.serverLogs;
    });
  }

  async appMetricaInitialization() {
    if (Capacitor.getPlatform() !== 'web') {
      const key =
        BuildVersion.legal && this._platform.is('ios')
          ? 'd39411d8-c662-4e6b-bfec-e21539c93aa2'
          : environment.appMetricaKey;
      await this.appmetrica.activate(key, { logs: true });
      this.appMetricaLogEvent('First Open');
    }
  }

  async appMetricaLogEvent(name: string, params?: object) {
    if (Capacitor.getPlatform() !== 'web') {
      await this.appmetrica.reportEvent(name, params);
    }
  }

  async appMetricaSetUserProfileID(id: string) {
    if (Capacitor.getPlatform() !== 'web') {
      return this.appmetrica.setUserProfileID(id);
    }
  }

  private sendEvent(name: string, payload?: any) {
    amplitude.getInstance().logEvent(name, payload);
    this.appMetricaLogEvent(name, payload);
  }

  private identify(payload: Record<string, any>) {
    //amplitude
    try {
      const identify = new amplitude.Identify();
      for (const key of Object.keys(payload)) {
        identify.setOnce(key, payload[key]);
      }
      amplitude.getInstance().identify(identify);
    } catch (error) {
      console.error(error);
    }

    //appmetrica
    try {
      if (Capacitor.getPlatform() !== 'web') {
        const userProfile = new UserProfile();

        for (const key of Object.keys(payload)) {
          const value = payload[key];
          let profileUpdate: UserProfileUpdate;
          switch (typeof value) {
            case 'string':
              profileUpdate =
                ProfileAttribute.CustomString(key).withValue(value);
              break;
            case 'number':
              profileUpdate =
                ProfileAttribute.CustomNumber(key).withValue(value);
              break;
            case 'boolean':
              profileUpdate = ProfileAttribute.CustomBool(key).withValue(value);
              break;
            default:
              // profileUpdate = ProfileAttribute.CustomString(key).withValue(
              //   JSON.stringify(value)
              // );
              break;
          }
          userProfile.apply(profileUpdate);
        }
        // this.appmetrica.reportUserProfile(userProfile);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // async reportUserProfile() {
  //   const userProfile = new UserProfile();
  //   userProfile.applyFromArray([
  //     ProfileAttribute.Name().withValue('Ivan'),
  //     ProfileAttribute.BirthDate().withBirthDate(new Date()),
  //     ProfileAttribute.CustomString('born_in').withValueIfUndefined('Moscow'),
  //   ]);

  //   await this.appmetrica.reportUserProfile(userProfile);
  // }

  // async getDeviceID(): Promise<string> {
  //   return this.appmetrica.getDeviceID();
  // }

  public setUserId(userId: string) {
    amplitude.getInstance().setUserId(userId);
    this.appMetricaSetUserProfileID(userId);
  }

  public setSplitTests(splitTests: any[]) {
    this.identify({ split_tests: splitTests });
  }

  public setIsGuest(isGuest: boolean) {
    this.identify({ is_guest: isGuest });
  }

  public gameStart(): void {
    if (!this.isGameStarted) {
      this.isGameStarted = true;
    }
  }

  public clickOnUpdateApk(updateFrom: string, updateTo: string) {
    this.sendEvent('Click on update APK', { updateFrom, updateTo });
  }

  public authorize(method: SignMethod, type: 'log_in' | 'sign_up') {
    this.sendEvent(type, { method });
  }

  public tutorial(step: number | 'tinder', isLast: boolean): void {
    if (isLast) {
      this.sendEvent('tutorial_completed_step', { step });
    }
  }

  public showRegForm(data: ShowRegistrationFormData) {
    this.sendEvent('Show Auth Form', { ...data.data });
  }

  public openMessage(messageData: {
    messageId: string;
    chatId: string;
    contentLink: string;
  }): void {
    if (this.messageLimit < 10) {
      this.sendEvent('open_message', {
        message: messageData.messageId,
        chat: messageData.chatId,
        readCount: this.messageLimit,
      });
      this.messageLimit++;
    }
    if (messageData.contentLink) {
      this.sendEvent('media_received', {
        message: messageData.messageId,
        chat: messageData.chatId,
        link: messageData.contentLink,
      });
    }
  }

  public savePhoto(chatId, photoId): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    this.sendEvent('save_photo', { chatId, photoId });
  }

  public saveVideo(chatId, photoId): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    this.sendEvent('save_video', { chatId, photoId });
  }

  public unlockingChat(): void {
    this.sendEvent('unlocking_chat');
  }

  public chatIsOffline(chatId: string, messageIndex): void {
    this.sendEvent('chat_is_offline', { chatId, messageIndex });
  }

  public openGallery(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    this.sendEvent('open_gallery');
  }

  public openShop(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    this.sendEvent('open_shop');
    console.log('Open shop');
  }

  public openSettings(): void {
    this.sendEvent('open_settings');
  }

  public skipOfflineModal(
    chatId: string,
    name: string,
    cost: number,
    skipReason: ReasonType = undefined
  ): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('skip_offline', { chatId, name, cost, skipReason });
  }

  public openAllChats(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    // this.sendEvent('open_all_chats'); // Disabled coz many requests
  }

  public swipeModel(
    id: string,
    name: string,
    isLiked: boolean,
    chatType: SwipingCardType
  ): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('swipe_model', {
      id,
      name,
      isLiked,
      chatType,
    });
  }

  public cardsFirstMatch(
    id: string,
    name: string,
    isLiked: boolean,
    chatType: SwipingCardType
  ) {
    this.sendEvent('cards_first_match', {
      id,
      name,
      isLiked,
      chatType,
    });
  }

  public swipeLimit(cardCount: number, gemsCount: number): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('swipe_limit', {
      cardCount,
      gemsCount,
    });
  }

  public swipeMatchSucceed(
    id: string,
    productPrice: number,
    currencyType: string,
    type: any
  ): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('swipe_match_succeed', {
      id,
      productPrice,
      currencyType,
      type,
    });
  }
  public trialMatched(chatId: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('trial_chat_matched', {
      chatId,
    });
  }

  public trialPurchaseModalAppeared(
    chatId: string,
    messagesCount: number
  ): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('trial_purchase_modal_appeared', {
      chatId,
      messagesCount,
    });
  }
  public trialPurchaseClickBuy(chatId: string, messagesCount: number): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('trial_purchase_click_buy', {
      chatId,
      messagesCount,
    });
  }

  public openSwipingCardsPage(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('open_swiping_cards_page');
  }

  public progressPopupAction(action: string, calledPlace: string) {
    this.sendEvent('Save Progress Popup', { action, calledPlace });
  }

  public openChat(id: string, name: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('open_chat', { id, name });
  }

  public buyChatForSoft(id: string, name: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('Buy Chat For Soft', { id, name });
  }

  public clickOnPurchase(
    target: string,
    id: string,
    name: string,
    source: PurchaseItemType,
    offerType = ''
  ): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    this.sendEvent('click_on_purchase', {
      target,
      id,
      name,
      source,
      offerType,
    });
  }

  public openBonus(): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('open_bonus');
  }

  public collectDailyBonus(payload: { bonusReason: ReasonType }): void {
    this.sendEvent('collect_daily_bonus', payload);
  }

  public showBonus(bonus: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('show_bonus', { bonus });
  }

  public getBonus(day: number, bonus?: string): void {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }

    this.sendEvent('get_bonus', { bonus, day });
  }

  public chatFeedbackFormSent(props: {
    chatId: string;
    storyRating: number;
    girlRating: number;
    translateQuality: number;
    bonusReason?: ReasonType;
  }): void {
    this.sendEvent('chat_feedback_form_sent', props);
  }

  public confirmPurchaseModal(data: RealPurchaseData) {
    this.sendEvent('confirm_purchase_modal', { ...data });
  }

  public notEnoughFunds(
    transactionId: string,
    productPrice: number,
    productName: string
  ) {
    this.sendEvent('not_enough_funds', {
      transactionId,
      productPrice,
      productName,
    });
  }

  public notAuthorizedInPurchase(id, source) {
    this.sendEvent('not_authorized_purchase', {
      id,
      source,
    });
  }

  public notEnoughFundsDismiss() {
    this.sendEvent('not_enough_funds_dismiss');
  }

  public notEnoughFundsConfirm(eroUrl) {
    this.sendEvent('not_enough_funds_confirm', { ...eroUrl });
  }

  public paymentError(paymentMethod, transactionId, productName) {
    this.sendEvent('Payment Error', {
      paymentMethod,
      transactionId,
      productName,
    });
  }
  public buyGiftLot(lotType, productId, isSuccess: boolean) {
    this.sendEvent('Buy Gift Lot', { lotType, productId, isSuccess });
  }

  public paymentIssued(
    transactionId: string,
    productPrice: number,
    productName: string,
    lotType: string,
    source: string,
    meta: string
  ) {
    this.sendEvent('Issued payment', {
      transactionId,
      productPrice,
      productName,
      lotType,
      source,
      meta,
    });
  }

  public payment(
    paymentMethod: string,
    transactionId: string,
    productPrice: number,
    productName: string,
    lotType: string,
    source: string,
    meta: string,
    offerType: string = '',
    purchaseInfo: any,
    status: any
  ): void {
    const purchaseInfoStringify = JSON.stringify(purchaseInfo);
    this.sendEvent('realPayment', {
      paymentMethod,
      transactionId,
      productPrice,
      productName,
      lotType,
      source,
      meta,
      offerType,
      purchaseInfoStringify,
      status,
    });

    const revenue = new amplitude.Revenue()
      .setProductId(transactionId)
      .setPrice(productPrice)
      .setQuantity(1);
    amplitude.getInstance().logRevenueV2(revenue);

    const appMetricaRevenue = {
      productId: productName,
      price: productPrice,
      currency: 'USD',
      quantity: 1,
      transactionId,
      receipt: 'receipt_data',
    };
    this.appmetrica.reportEvent('revenue', appMetricaRevenue);
  }

  public quickMessaging(action: boolean) {
    this.sendEvent('quick_messaging', {
      action,
    });
  }

  public onlineStreamNotifications(action: boolean) {
    this.sendEvent('Global Streaming Notifications', {
      action,
    });
  }

  public onlineStreamModelNotifications(chatId, name, action: boolean) {
    this.sendEvent('Model Streaming Notifications', {
      chatId,
      name,
      action,
    });
  }

  public showStreamToast(data: ToastOnlineStreamData) {
    this.sendEvent('show Stream Toast', { ...data });
  }

  public clickOnOnlineStream(source: 'button' | 'toast', chatId: string) {
    this.sendEvent('click On Online Stream', { source, chatId });
  }

  public music(action: 'on' | 'off') {
    this.sendEvent('turn_music', { action });
  }

  public sounds(action: 'on' | 'off') {
    this.sendEvent('turn_sounds', { action });
  }

  public showDownloadApkModal(place, action) {
    this.sendEvent('Show download apk modal', { place, action });
  }

  public logout() {
    this.sendEvent('log_out');
  }

  public buyAdditionalContent(action: 'buy' | 'close', name: string) {
    if (this._tutorialService.tutorialModeOn) {
      return;
    }
    this.sendEvent('buy_additional_content', { action, name });
  }

  public clickIdSet(clickId: string, partnerId: string) {
    try {
      this.identify({ click_id: clickId, partner_id: partnerId });
    } catch (e) {
      console.error('Analytics click id set error', e);
    }
  }

  public setTrafficCompany(companySIgn: string) {
    try {
      this.identify({ traffic_company: companySIgn });
    } catch (e) {
      console.error('Analytics set traffic company error', e);
    }
  }

  public landingBehaviorSet(landingBehavior: string) {
    try {
      this.identify({ landing_behavior: landingBehavior });
    } catch (e) {
      console.error('Analytics landing behavior set error', e);
    }
  }

  public trafficSourceSet(ts: string) {
    // DEPRECATED TODO remove all trsrc
    try {
      this.identify({ traffic_source: ts });
    } catch (e) {
      console.error('Analytics traffic source set error', e);
    }
  }

  public utmSourceSet(
    utmSource: string,
    utmMedium: string,
    utmCampaign: string,
    utmTerm: string
  ) {
    try {
      this.identify({
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_term: utmTerm,
      });
    } catch (e) {
      console.error('Analytics traffic source set error', e);
    }
  }

  public setPlatform() {
    const devicePlatform = this._platform.is('hybrid') ? 'apk' : 'web';
    try {
      this.identify({
        host_platform: environment.buildVersion,
        device_patform: devicePlatform,
        user_patforms: this._platform.platforms(),
      });
    } catch (e) {
      console.error('Analytics set platforms error', e);
    }
  }

  private _preparePromoData(data: PromoAnalyticsData): string {
    const nameArr = [];
    if (data?.section) {
      nameArr.push(data.section);
    }
    if (data?.chatName) {
      nameArr.push(data.chatName);
    }
    if (data?.placeInSection) {
      nameArr.push(data.placeInSection);
    }
    if (data?.positionInChatList) {
      nameArr.push(data.positionInChatList);
    }
    if (data?.messageIndex) {
      nameArr.push(data.messageIndex);
    }
    if (data?.bannerSlide) {
      nameArr.push(data.bannerSlide);
    }

    const name = nameArr.join(' / ');
    return name;
  }

  private _getLinkParams(link): any {
    let linkParams;
    if (link) {
      const url = new URL(link);
      const params = new URLSearchParams(url.search);
      linkParams = Object.fromEntries(params.entries());
    } else {
      linkParams = {};
    }
    return linkParams;
  }

  public androidDevTool(name: string, data) {
    this.sendEvent(name, data);
  }

  public clickOnPromoWidget(data: PromoAnalyticsData) {
    const name = this._preparePromoData(data);

    const linkParams = this._getLinkParams(data.link);

    // promoCompany: string, name: string, positionInChatsList: number, bannerPlaced?: string

    this._settingsDataService.updateSettingsData
      .pipe(
        filter((res) => res.id !== null),
        take(1)
      )
      .subscribe((res) => {
        this.sendEvent('click on promo', {
          userId: res.id,
          sessionid: this._tokenService.getToken(),
          promoCompany: data.promo,
          name,
          ...linkParams,
          ...data,
        });
      });
  }

  public promoIsShown(data: PromoAnalyticsData) {
    const name = this._preparePromoData(data);
    const linkParams = this._getLinkParams(data?.link);
    this.sendEvent('Promo Is Shown', {
      promoCompany: data?.promo || null,
      name,
      ...data,
      ...linkParams,
    });
  }

  public clickOnDiscordLink(place = 'chats') {
    this.sendEvent('click on Discord', { place });
  }

  public activatedPromoCode(promoCode: string) {
    this.sendEvent('Activated Promo Code', { promoCode });
  }

  public clickOnResendEmail() {
    this.sendEvent('Click on resend email');
  }

  public clickOnSubscribeToPush() {
    this.sendEvent('Subscribe to Push');
  }

  public isBanned(chatId: string, name: string, messageIdx: number) {
    this.sendEvent('User Is Banned', { chatId, name, messageIdx });
  }

  public clickOnUnban(chatId: string, name: string, messageId: number) {
    this.sendEvent('Click on Unban btn', { chatId, name, messageId });
  }

  public unbanBoughtBySoft(chatId: string, coins: number) {
    this.sendEvent('Unbun Bought By Soft', { chatId, coins });
  }

  public showGiftsModal(chatId: string, name: string, openFrom: string) {
    this.sendEvent('Show Gifts Modal', { chatId, name, openFrom });
  }

  public buyGift(chatId: string, name: string, giftId: string) {
    this.sendEvent('Buy Gift', { chatId, name, giftId });
  }

  public nicknameModalAppeared(place: string) {
    this.sendEvent('Nickname modal appeared', { place });
  }

  public showNicknameInput(chatId: string) {
    this.sendEvent('Show Nickname Input', { chatId });
  }

  public showAgeInput(chatId: string) {
    this.sendEvent('Show Age Input', { chatId });
  }

  public showAgreementInput(chatId: string) {
    this.sendEvent('Show Agreement Input', { chatId });
  }

  public nicknameIsSet(nickname: string, place: string, handWrited: boolean) {
    this.sendEvent('Nickname set', { nickname, place, handWrited });
  }

  public ageIsSet(over18: boolean) {
    this.sendEvent('Age set', { over18 });
  }

  public agreementIsSet() {
    this.sendEvent('Agreement set', { confirm: true });
  }

  public clickOnOver18(abTestVersion) {
    this.sendEvent('click_on_over_18', {
      clickedInSeconds: this.idleSecondsCount,
      abTestVersion,
    });
    clearInterval(this.idleOnWebsiteTracker);
  }
  public showOver18(abTestVersion) {
    this.sendEvent('show_over_18_modal', { abTestVersion });

    // this.idleSecondsCount = 1;
    // this.idleOnWebsiteTracker = setInterval(() => {
    //   if (this.idleSecondsCount < 10) {
    //     amplitude.logEvent(`${this.idleSecondsCount} second in game`);
    //     this.idleSecondsCount += 1;
    //   } else {
    //     clearInterval(this.idleOnWebsiteTracker);
    //   }
    // }, 1000);
  }

  public showOffer(offerdata: OfferData) {
    this.sendEvent('show_offer', { ...offerdata });
  }
  public showCardsFinishedModal() {
    this.sendEvent('Show cards finished');
  }

  public nativePushReceived(notitfication: PushNotificationSchema) {
    this.sendEvent('Native Push Received', {
      ...notitfication,
      ...notitfication?.data,
    });
  }

  public nativePushActionPerformed(notitfication: PushNotificationSchema) {
    this.sendEvent('Native Push Action Performed', {
      ...notitfication,
      ...notitfication?.data,
    });
  }

  public webPushPermissionGranted() {
    this.sendEvent('Web Push Permission Granted');
  }

  public webPushReceived(notification) {
    this.sendEvent('Web Push Received', { ...notification });
  }

  public showNutakuAuthModal() {
    this.sendEvent('Show Nutaku Auth Modal');
  }

  public setNutakuVersion(version: string) {
    try {
      this.identify({ nutaku_version: version });
    } catch (e) {
      console.error('Analytics nutaku version set error', e);
    }
  }

  public showUserNotification(notification: UserNotification) {
    this.sendEvent('Show User Notification', { ...notification });
  }

  public confirmUserNotification(notification: UserNotification) {
    this.sendEvent('Confirm User Notification', { ...notification });
  }

  public showPremiumAccountModalPage(
    subscriptionStatus: SubscriptionStatus,
    callFrom: string
  ) {
    this.sendEvent('Show Premium Account Modal Page', {
      subscriptionStatus,
      callFrom,
    });
  }
  public premiumModalNext(step, price) {
    this.sendEvent('Premium modal next', {
      step,
      price,
    });
  }

  public premiumModalClose(price) {
    this.sendEvent('Premium modal close', {
      price,
    });
  }

  public audioMessageEvent(
    event: AudioMessageAnalyticsEvent,
    message: NextMessage
  ) {
    this.sendEvent(event, {
      chatId: message.chatId,
      messageId: message.id,
    });
  }

  public onSetLanguage(language: Language, setByUser: boolean): void {
    this.sendEvent('language set', {
      language,
      setByUser,
    });
  }

  public onLanguageSelectorOpen(place: string): void {
    this.sendEvent('language selector open', {
      place,
    });
  }

  public onPremiumPurchasedFromSwipe(): void {
    this.sendEvent('premium_purchased_from_swipe');
  }

  public serverLogging(action, data: any) {
    // this.sendEvent('Legal shopping', { action, id });
    if (this._settingsDataService.serverLogs) {
      const requestUrl = `https://testapi.getmynudes.com/api/Support/log`;
      this._http.post(requestUrl, {action, data }).pipe(take(1)).subscribe();
    }
  }

  public onPaidAnswerClick(payload: {
    messageId: string;
    answerId: number;
    enoughGems: boolean;
  }): void {
    this.sendEvent('paid_answer_click', payload);
  }

  public adWatched(place: AdPlace): void {
    this.sendEvent('adWatched ', { place });
  }

  public getPhoto(payload: {
    name: string;
    messageIndex: number;
    chatId: string;
  }): void {
    this.sendEvent('get_photo', payload);
  }

  public fetchMessagesPack(payload: {
    name: string;
    messageIndex: number;
    chatId: string;
  }): void {
    this.sendEvent('fetch_messages_pack', payload);
  }

  public likePhoto(payload: {
    type: 'like' | 'dislike';
    name: string;
    messageIndex: number;
    chatId: string;
  }): void {
    this.sendEvent('like_photo', payload);
  }

  public claimDailyQuest(type: DailyQuestType): void {
    this.sendEvent('claim_daily', { type });
  }

  public claimAllDailyQuests(): void {
    this.sendEvent('claim_all_daily', {});
  }
}
