import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ShopLot } from '../../types/shop-lot-new.interface';
import { WebsocketSignalRService } from '../../services/websocket-signalr.service';
import { WebsocketCommandType } from '../../enums/websocket-command-type.enum';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';
import { RealPurchaseData } from '../../types/purchase-data.interface';
import { environment } from 'src/environments/environment';
import { PaymentsService } from '../../services/payments/payments.service';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ShopPacksService {
  public packs = new BehaviorSubject<ShopLot[]>([]);

  constructor(
    private _websocketSignalRService: WebsocketSignalRService,
    private _paymentsService: PaymentsService
  ) {}

  public fetchPacks(): Observable<boolean> {
    return this._websocketSignalRService
      .invoke<ShopLot[]>(WebsocketCommandType.getPacks, {})
      .pipe(
        take(1),
        map((res) => {
          this.packs.next(res);
          return true;
        })
      );
  }

  public purchase(pack: ShopLot): Observable<boolean> {
    const data: RealPurchaseData = {
      source: PurchaseItemType.offerBanner,
      purchase: pack.purchase,
      productId: pack.purchase.productId,
    };
    if (environment.buildVersion === BuildVersion.nutaku) {
      data.nutakuData = {
        nutakuName: 'Offer',
        nutakuDescription: '',
        nutakuImageUrl:
          'https://nutaku.getmynudes.com/assets/img/logo-gmn-for-nutaku.png',
      };
    }
    return this._paymentsService.purchase(data).pipe(take(1));
  }
}
