import { NutakuLoginModalComponent } from './nutaku-login-modal/nutaku-login-modal.component';
import { BannerSliderModule } from './../banner-slider/banner-slider.module';
import { MaintenanceModalComponent } from './maintenance-modal/maintenance-modal.component';
import { OfferModalComponent } from './offer-modal/offer-modal.component';
import { MatchModalComponent } from './match-modal/match-modal.component';
import { LottieModule } from 'ngx-lottie';
import { RewardsReceivedModalComponent } from './rewards-received-modal/rewards-received-modal.component';
import { UpdateVersionModalComponent } from './update-version-modal/update-version-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterNicknameModalComponent } from './enter-nickname-modal/enter-nickname-modal.component';
import { FormsModule } from '@angular/forms';
import { TrialPurchaseModalComponent } from './trial-purchase-modal/trial-purchase-modal.component';
import { TrialPurchaseModalAbComponent } from './trial-purchase-modal-ab/trial-purchase-modal-ab.component';
import { ChatDetailsModalComponent } from './chat-details-modal/chat-details-modal.component';
import { IonicModule } from '@ionic/angular';
import { OffersModule } from '../offers/offers.module';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonNotificationModalComponent } from './common-notification-modal/common-notification-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    UpdateVersionModalComponent,
    RewardsReceivedModalComponent,
    EnterNicknameModalComponent,
    MatchModalComponent,
    TrialPurchaseModalComponent,
    TrialPurchaseModalAbComponent,
    ChatDetailsModalComponent,
    OfferModalComponent,
    MaintenanceModalComponent,
    NutakuLoginModalComponent,
    CommonNotificationModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LottieModule,
    IonicModule,
    OffersModule,
    BannerSliderModule,
    PipesModule,
    TranslateModule,
  ],
  exports: [
    UpdateVersionModalComponent,
    RewardsReceivedModalComponent,
    EnterNicknameModalComponent,
    MatchModalComponent,
    TrialPurchaseModalComponent,
    ChatDetailsModalComponent,
    NutakuLoginModalComponent,
    CommonNotificationModalComponent,
  ],
})
export class MiscAlertsModule {}
