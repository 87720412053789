import { ThreeGermanChatsComponent } from './templates/three-german-chats/three-german-chats.component';
import { TwoHotLesbianComponent } from './templates/two-hot-lesbian/two-hot-lesbian.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { PremiumSubscriptionComponent } from './templates/premium-subscription/premium-subscription.component';
import { PriceBlockComponent } from './templates/price-block/price-block.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatsPackComponent } from './templates/chats-pack/chats-pack.component';
import { ExclusivePackComponent } from './templates/exclusive-pack/exclusive-pack.component';
import { FiveLikesComponent } from './templates/five-likes/five-likes.component';
import { NewYearComponent } from './templates/new-year/new-year.component';
import { OffersComponent } from './offers.component';
import { LifetimeOfferComponent } from './templates/lifetime/lifetime.component';
import { OfferTimerComponent } from './templates/timer/timer.component';
import { OfferPriceComponent } from './templates/offer-price/offer-price.component';
import { ValentineDaySmallOfferComponent } from './templates/valentine-day-small/valentine-day-small.component';
import { ValentineDayBigOfferComponent } from './templates/valentine-day-big/valentine-day-big.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpringBreakSmallOfferComponent } from './templates/spring-break-small/spring-break-small.component';
import { SpringBreakBigOfferComponent } from './templates/spring-break-big/spring-break-big.component';
import { PriceBlockNewComponent } from './price-block-new/price-block-new.component';
import { SubscriptionTextComponent } from './subscription-text/subscription-text.component';
import { SpringCleaningPackComponent } from './templates/spring-cleaning-pack/spring-cleaning-pack.component';

@NgModule({
  declarations: [
    ChatsPackComponent,
    ExclusivePackComponent,
    FiveLikesComponent,
    OffersComponent,
    PriceBlockComponent,
    PremiumSubscriptionComponent,
    NewYearComponent,
    LifetimeOfferComponent,
    OfferTimerComponent,
    OfferPriceComponent,
    ValentineDaySmallOfferComponent,
    ValentineDayBigOfferComponent,
    TwoHotLesbianComponent,
    ThreeGermanChatsComponent,
    SpringBreakSmallOfferComponent,
    SpringBreakBigOfferComponent,
    PriceBlockNewComponent,
    SubscriptionTextComponent,
    SpringCleaningPackComponent
  ],
  imports: [CommonModule, PipesModule, TranslateModule],
  exports: [
    ChatsPackComponent,
    ExclusivePackComponent,
    FiveLikesComponent,
    OffersComponent,
    PriceBlockComponent,
    NewYearComponent,
    LifetimeOfferComponent,
    OfferTimerComponent,
    OfferPriceComponent,
    ValentineDaySmallOfferComponent,
    ValentineDayBigOfferComponent,
    TwoHotLesbianComponent,
    ThreeGermanChatsComponent,
    SpringBreakSmallOfferComponent,
    SpringBreakBigOfferComponent,
    PriceBlockNewComponent,
    SubscriptionTextComponent,
    SpringCleaningPackComponent
  ],
})
export class OffersModule {}
