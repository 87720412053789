<div class="rating-bar-container">
  <div *ngIf="isTutorialActive" class="blur-bg"></div>

  <div class="hole-in-header"></div>
  <div class="hearts">
    <ng-lottie
      class="lottie-btn"
      (animationCreated)="heartAnimationCreate($event)"
      [options]="heartRateAnimationOptins"
    ></ng-lottie>
    <span
      >{{ level }}
      <div
        *ngIf="isTutorialActive && tutorialStep === 1"
        class="tutorial-rating-second-step"
      >
        <div class="tutorial-rating-popup second-step">
          {{"SHARED.AT_A_NEW_LEVEL_DESCR" | translate}}

          <button (click)="completeTutorial()" class="tutorial-btn-ok">
            <img
              [src]="
                environment.rootPath +
                '/assets/img/chat-page/rating-tutorial/check-icon.svg'
              "
            />
          </button>
        </div>

        <div class="hearts-icon">
          <img
            [src]="
              environment.rootPath +
              '/assets/img/chat-page/rating-tutorial/hearts.svg'
            "
          />
          <div>
            {{ level }}
          </div>
        </div>
      </div>
    </span>
    <div class="new-level-up-animation" *ngIf="levelUp">
      <ng-lottie
        class="lottie-btn"
        [options]="saveToGalleryAnimationOptions"
      ></ng-lottie>
    </div>
  </div>
  <div class="rating-bar-line">
    <div class="rating-bar-grey-line" [style.width]="(rating$ | async) + '%'">
      <div id="chat-rating-value" class="current-rating-display">
        {{ chatRating }}
        <div
          *ngIf="isTutorialActive && tutorialStep === 0"
          class="tutorial-rating-first-step"
        >
          <div
            class="tutorial-rating-popup first-step"
            [style]="{
              '--offset': firstTutorialStepPopupOffset
            }"
          >
          {{"SHARED.BE_CAREFUL_IN_CHOOSING_THE_ANSWERS" | translate}}

            <b
              >{{"SHARED.THE_ATTITUDE_OF_THE_GIRL_DESCR" | translate}}</b
            >
            <button (click)="nextTutorialStep()" class="tutorial-btn-ok">
              <img
                [src]="
                  environment.rootPath +
                  '/assets/img/chat-page/rating-tutorial/arrow-right.svg'
                "
              />
            </button>
          </div>
          <div class="tutorial-rating-display">
            <div class="middle-circle">
              {{ chatRating }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
