<!-- BANNER EXCLUSIVE -->
<div
  *ngIf="appearanceType === 'banner'"
  class="pcb-container offer-banner-container premium-subscription-new"
>
  <div class="bg-wrapper">
    <img
      [src]="environment.rootPath + '/assets/img/premium/bokeh.png'"
      draggable="false"
      class="bokeh"
    />
  </div>

  <div class="description-block">
    <h2 [innerHTML]="'OFFERS.PREMIUM.GET_THEM_ALL' | translate"></h2>
    <p>
      {{ "OFFERS.PREMIUM.DESCRIPTION" | translate }}
    </p>
  </div>

  <div class="img-block">
    <div class="vip-img">
      <img
        *ngIf="environment.buildVersion !== buildVersion.legal"
        [src]="environment.rootPath + '/assets/img/premium/premium.png'"
        draggable="false"
      />
      <img
        *ngIf="environment.buildVersion === buildVersion.legal"
        [src]="environment.rootPath + '/assets/img/premium/premium-legal.png'"
        draggable="false"
      />
      <img
        [src]="environment.rootPath + '/assets/img/premium/lensFlare.png'"
        draggable="false"
        class="flare"
      />
    </div>

    <a class="read-more-btn" (click)="clickOnReadMore($event)">
      {{ "OFFERS.PREMIUM.READ_MORE" | translate }}
      <img
        [src]="environment.rootPath + '/assets/img/three-dots.svg'"
        draggable="false"
      />
    </a>
  </div>

  <app-price-block
    [offerData]="offerData"
    [subscriptionText]="true"
    class="subscribtion-price-block"
    [class.legal]="environment.buildVersion === buildVersion.legal"
  ></app-price-block>

  <div
    *ngIf="environment.buildVersion !== buildVersion.legal"
    class="sale-block"
  >
    <span class="sale"> {{ "OFFERS.SALE" | translate }}</span>
    <span class="percents">{{ offerData?.purchase.discount }}%</span>
    <span class="ring"></span>
  </div>
</div>

<div
  class="pcb-container slider-modal premium-subscription-success-modal"
  *ngIf="appearanceType === 'modal' && purchased"
>
  <div class="head">
    <img
      [src]="environment.rootPath + '/assets/img/premium/bokeh.png'"
      draggable="false"
      class="bokeh"
    />
    <img
      *ngIf="environment.buildVersion !== buildVersion.legal"
      [src]="environment.rootPath + '/assets/img/premium/premium.png'"
      draggable="false"
      class="premium-image"
    />
    <img
      *ngIf="environment.buildVersion === buildVersion.legal"
      [src]="environment.rootPath + '/assets/img/premium/premium-legal.png'"
      draggable="false"
      class="premium-image"
    />
    <h2>{{ "OFFERS.PREMIUM.CONGRATULATIONS" | translate }}</h2>
    <p>{{ "OFFERS.PREMIUM.YOU_HAVE_PREMIUM" | translate }}</p>
  </div>
  <div class="modal-content">
    <p [innerHTML]="'OFFERS.PREMIUM.ALL_GIRLS_FREE' | translate"></p>
    <a class="chat-btn" (click)="close()">
      {{ "SHARED.CHAT_NOW" | translate }}
    </a>
  </div>
</div>
