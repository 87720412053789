import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumModalComponent } from './premium-modal.component';
import { SwiperModule } from 'swiper/angular';
import { OffersModule } from '../../offers/offers.module';
import { PremiumUnsubscribeModalModule } from '../premium-unsubscribe-modal/premium-unsubscribe-modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [PremiumModalComponent],
  imports: [
    CommonModule,
    SwiperModule,
    OffersModule,
    PremiumUnsubscribeModalModule,
    PipesModule,
    TranslateModule,
  ],
  exports: [PremiumModalComponent],
})
export class PremiumModalModule {}
