export enum OfferType {
  fiveChats = 'FiveChats',
  threeChats = 'ThreeChats',
  oneChatAndGems = 'OneChatAndGems',
  hiddenFiveChats = 'HiddenFiveChats',
  oneCheapChat = 'OneCheapChat',
  subscription = 'Subscription',
  subscriptionContinue = 'SubscriptionContinue',
  subscriptionRenewal = 'SubscriptionRenewal',
  twoChatsForNewYear = 'TwoChatsForNewYear',
  lifetimeSubscription = 'LifetimeSubscription',
  valentineDaySmall = 'ValentineDaySmall',
  valentineDayBig = 'ValentineDayBig',
  twoHotLesbianChats = 'TwoHotLesbianChats',
  threeGermanChats = 'ThreeGermanChats',
  springBreakSmall = 'SpringBreakSmall',
  springBreakBig = 'SpringBreakBig',
  starterPack = 'StarterPack',
  bigPack = 'BigPack',
  springCleaningOffer = 'SpringCleaningOffer',
  springCleaningOfferBig = 'SpringCleaningOfferBig',
}
