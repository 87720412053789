import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { from, map, Observable, of, switchMap, take } from 'rxjs';
import { ModalAnimationsService } from '../animations/modal-animations.service';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private _openModals: ComponentRef[] = [];

  constructor(
    private _modalController: ModalController,
    private _modalAnimationsService: ModalAnimationsService
  ) {}

  public openModal<T>(
    component: ComponentRef,
    componentProps: ComponentProps<ComponentRef> = {}
  ): Observable<T> {
    if (this._openModals.includes(component)) {
      return of(null);
    }

    return from(
      this._modalController.create({
        component,
        componentProps,
        canDismiss: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
        enterAnimation: this._modalAnimationsService.enterAnimation,
        leaveAnimation: this._modalAnimationsService.leaveAnimation,
      })
    ).pipe(
      take(1),
      switchMap((modal) => {
        this._openModals.push(component);
        modal.present();
        return from(modal.onDidDismiss()).pipe(
          map((res) => {
            this._openModals = this._openModals.filter((c) => c !== component);
            return res.data;
          })
        );
      })
    );
  }
}
