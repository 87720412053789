import { Component, Input, OnInit } from '@angular/core';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';
import { LegalPaymentService } from 'src/app/shared/services/payments/legal-payment.service';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-price-block',
  templateUrl: './price-block.component.html',
  styleUrls: ['./price-block.component.scss'],
})
export class PriceBlockComponent implements OnInit {
  @Input() offerData: OfferData;
  @Input() subscriptionText = false;
  environment = environment;
  buildVersion = BuildVersion;
  public isShowDiscount = false;
  constructor(public legalPaymentService: LegalPaymentService) {}

  ngOnInit() {
    this.isShowDiscount =
      environment.buildVersion !== BuildVersion.legal &&
      this.offerData.type !== OfferType.subscription;
  }
}
