<div class="game-bg" *ngIf="isDeskTop">
  <div class="game-bg-left-side"></div>
  <div class="game-bg-right-side"></div>
</div>

<ion-app
  [style]="{
    '--offset-top': offsetTop,
    '--padding-top': paddingTop,
    height: appHeight
  }"
  [class.blueBg]="currentRoute === '/' + routeName.allChats"
  [class.whiteBg]="currentRoute.includes(routeName.profile)"
  [class.isDesktop]="isDeskTop"
  [class.low-height]="isLowHeightScreen"
>
  <div
    class="connection-problems-toast-container"
    [ngClass]="{
      show: websocketSignalRService.connectionProblemToast$ | async
    }"
  >
    <div class="connection-problems-toast">
      <img src="/assets/img/reconnect-icon.svg" />
      {{ "SHARED.TRYING_TO_RECONNECT" | translate }}
    </div>
  </div>

  <app-header *ngIf="isShowHeader"></app-header>
  <!-- <div [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  </div> -->
  <ion-content
    id="ion-content"
    overflow-scroll="true"
    [scrollY]="!currentRoute.includes(routeName.swipingChatCards)"
  >
    <!-- <ion-refresher slot="fixed" (ionRefresh)="refreshConnection($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher> -->
    <router-outlet #o="outlet"></router-outlet>
  </ion-content>
  <app-footer *ngIf="isShowFooter" style="margin-top: auto"></app-footer>
  <app-loader *ngIf="isShowLoader"></app-loader>
</ion-app>
<app-payments></app-payments>
<app-admob-loader></app-admob-loader>
