<div class="coins-box" (click)="this.goToShop()">
  <img
    src="/assets/img/icon/cash.png"
    alt=""
    class="cash-icon"
    [class.diamond-big]="isDiamondScaled"
  />

  <div class="money-value">
    <app-animated-counter [value]="diamonds"></app-animated-counter>
  </div>

  <button class="add-btn">
    <img src="/assets/img/plus.svg" />
  </button>
</div>
